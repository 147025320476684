<div class="center">
    @if (signUpNotComplete) {
        <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6 ">
            <div class="text-center mb-5">
                <!--img src="assets/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3"-->
                <div class="text-900 text-3xl font-medium mb-3">Sign Up</div>
            </div>
        
            <div>
                
                <label for="firstname" class="block text-900 font-medium mb-2">Firstname</label>
                <input [(ngModel)]="newUser.firstname" id="firstname" type="text" placeholder="Firstname" pInputText class="w-full mb-3">
    
                <label for="lastname" class="block text-900 font-medium mb-2">Lastname</label>
                <input [(ngModel)]="newUser.lastname" id="lastname" type="text" placeholder="Lastname" pInputText class="w-full mb-3">
    
                <label for="email" class="block text-900 font-medium mb-2">Email</label>
                <input [(ngModel)]="newUser.email" id="email" type="text" placeholder="Email address" pInputText class="w-full mb-3">
     
                <label for="password1" class="block text-900 font-medium mb-2">Password</label>
                <input [(ngModel)]="newUser.password" id="password1" type="password" placeholder="Password" pInputText class="w-full mb-3">

                <label for="password2" class="block text-900 font-medium mb-2">Confirm Password</label>
                <input [(ngModel)]="newUser.confirmPassword" id="password2" type="password" placeholder="Password" pInputText class="w-full mb-3">

                <!--checkbox isShowowner and double bind with isShopOwner-->
                <!--label for="isShopOwner">Are you a Shop Owner?</label>
                <p-checkbox [(ngModel)]="newUser.isShopOwner" [binary]="true" id="isShopOwner"></p-checkbox-->

                @if (newUser.isShopOwner) {
                    <label for="shopname" class="block text-900 font-medium mb-2">Shopname</label>
                    <input [(ngModel)]="newShop.name" id="shopname" type="text" placeholder="Shopname" pInputText class="w-full mb-3">
                }
          
                <button pButton pRipple label="Sign Up" icon="pi pi-user" class="w-full" (click)="signUp()"></button>
            </div>
        </div>    
    } 
    @else {
        <!--h2>Welcome! Please confirm your E-Mail</h2-->
        <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
    } 
</div>
