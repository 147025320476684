<app-breadcrumb></app-breadcrumb>

<h1>Settings</h1>

<h3>Pickup Locations</h3>
<p>Enter the pickup locations the users should choose from</p>
@if(settings.data?.pickupLocations) {
    <p-chips [(ngModel)]="settings.data.pickupLocations" (focusout)="saveLocations()" />
} @else {
    <p>No pickupLocations found</p>
}
