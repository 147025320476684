<p-toast></p-toast>
<div class="center">
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6 ">
        <div class="text-center mb-5">
            <!--img src="assets/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3"-->
            <div class="text-900 text-3xl font-medium mb-3">Welcome Back</div>
            <span class="text-600 font-medium line-height-3">Don't have an account?</span>
            <a routerLink="/signup" class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a>
        </div>
    
        <div>
            <label for="email" class="block text-900 font-medium mb-2">Email</label>
            <input [(ngModel)]="loginUser.email"  id="email" type="text" placeholder="Email address" pInputText class="w-full mb-3">
    
            <label for="password" class="block text-900 font-medium mb-2">Password</label>
            <input [(ngModel)]="loginUser.password" id="password" type="password" placeholder="Password" pInputText class="w-full mb-3">
    
            <!--div class="flex align-items-center justify-content-between mb-6">
                <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot password?</a>
            </div-->
    
            <button pButton pRipple label="Sign In" icon="pi pi-user" class="w-full" (click)="login()"></button>
        </div>
    </div>
    
</div>
