import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { SelectItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { MessageModule } from 'primeng/message';
 
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { Allergen, CreateProductDto, DefaultService, Product } from '../../../../../../../shared-lib/src/lib/api';
import { HttpClientModule } from '@angular/common/http';
import { BreadcrumbComponent } from '../../../../components/auth/util/breadcrumb/breadcrumb.component';


interface CreateProductDtoWithAllergens extends CreateProductDto {
  allergens: Allergen[];
}


@Component({
  selector: 'app-products',
  standalone: true,
  imports: [CommonModule, HttpClientModule, FormsModule, InputTextModule,  ButtonModule, RouterLink, TableModule, DropdownModule, ToastModule, CardModule, MessageModule, DialogModule, InputNumberModule, InputTextareaModule, MultiSelectModule, BreadcrumbComponent],
  providers: [DefaultService, MessageService],
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss'
})
export class ProductsComponent {


  selectedCategoryId: string = "not set";

  showAddProductDialog: boolean = false;

  title: string = 'Products';

  products!: Product[];

  statuses!: SelectItem[];

  clonedProducts: { [s: string]: Product } = {};

  newProductWithAllergens: CreateProductDtoWithAllergens = {
    categoryid: '',
    name: '',
    description: '',
    netprice: 0,
    tax: 0,
    status: '',
    allergens: [],
  };

  allergensToSelect: Allergen[] = [];


  constructor(public defaultService: DefaultService, private messageService: MessageService, private route: ActivatedRoute) {}

  async ngOnInit() {
      // get categoryId from route
      this.route.params.subscribe((params) => {
        this.selectedCategoryId = params['categoryId'];
      });
      

      // find all where categoryid = selectedCategoryId
      this.defaultService.categoriesControllerFindAllProductsByCategoryId(this.selectedCategoryId).subscribe((response: any) => {
          this.products = response;
      });

      this.defaultService.allergensControllerFindAll().subscribe((response: any) => {
        this.allergensToSelect = response;
      });

      this.statuses = [
          { label: 'In Stock', value: 'INSTOCK' },
          { label: 'Low Stock', value: 'LOWSTOCK' },
          { label: 'Out of Stock', value: 'OUTOFSTOCK' }
      ];

  }

  onRowEditInit(product: Product) {
      this.clonedProducts[product.id as string] = { ...product };
  }

  onRowEditSave(product: Product) {
      if (product.netprice > 0) {
          delete this.clonedProducts[product.id as string];
          this.defaultService.productsControllerUpdate(product.id as string, product).subscribe(() => {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Product is updated' });
          });
      } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Price' });
      }
  }

  onRowEditCancel(product: Product, index: number) {
      this.products[index] = this.clonedProducts[product.id as string];
      delete this.clonedProducts[product.id as string];
  }

  onRowDelete(product: Product) {

      this.defaultService.productsControllerUpdateAllergensForProductId(product.id as string, []).subscribe(() => {
        console.log('Allergens removed');

        this.defaultService.productsControllerRemove(product.id as string).subscribe(() => {
          this.products = this.products.filter(val => val.id !== product.id);
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Product is deleted' });
      });
      });


  }

  addProduct() {
    this.newProductWithAllergens.categoryid = this.selectedCategoryId;

    console.log(this.newProductWithAllergens);
 
    const selectedAllergensForProduct: Allergen[] = this.newProductWithAllergens.allergens;
 
    const newProduct: CreateProductDto =  { 
      categoryid: this.newProductWithAllergens.categoryid,
      name: this.newProductWithAllergens.name,
      description: this.newProductWithAllergens.description,
      netprice: this.newProductWithAllergens.netprice,
      tax: this.newProductWithAllergens.tax,
      status: this.newProductWithAllergens.status,
    };

    this.defaultService.productsControllerCreate(newProduct).subscribe((response: any) => {

  
      this.defaultService.productsControllerCreateAllergensForProductId(response.id, selectedAllergensForProduct).subscribe(() => {
        console.log('Allergens added');

        this.products.push({
          id: response.id,
          categoryid: response.categoryid,
          name: response.name,
          netprice: response.netprice,
          tax: response.tax,
          status: response.status,
          description: response.description,
         // allergens: response.allergens
        } as Product);

        this.showAddProductDialog = false;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: response.name + ' was added' });
  
        this.newProductWithAllergens = {
          categoryid: this.selectedCategoryId,
          name: '',
          description: '',
          netprice: 0,
          tax: 0,
          status: '',
          allergens: [],
        };
      });
      
  
    });}

    setTitle(event: any) {
      this.title = event;
     }

}
