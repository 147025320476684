import { Component, OnInit, ViewChild } from '@angular/core';
 
import { ButtonModule } from 'primeng/button';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { Router, RouterOutlet } from '@angular/router';
import { AvatarModule } from 'primeng/avatar';
import { RouterLink } from '@angular/router';
import { OrdersComponent } from './orders/orders.component';
import { ProductsComponent } from './categories/products/products.component';
import { AuthService, DefaultService } from '../../../../../shared-lib/src/lib/api';
import { Observable } from 'rxjs';
import { DashboardService } from './dashboard.service';
import { ToolbarModule } from 'primeng/toolbar';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule,RouterLink, RouterOutlet, SidebarModule, OrdersComponent, ProductsComponent, ButtonModule, AvatarModule, AsyncPipe, ToolbarModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {

  public sidebarVisible: boolean = false;


  @ViewChild('sidebarRef') sidebarRef!: Sidebar;

  constructor(public dashboardService: DashboardService, private router: Router, private defaultService: DefaultService) { }
  
  ngOnInit(): void {  }


  closeCallback(e: Event): void {
      this.sidebarRef.close(e);
  }

  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }

  closeSidebar() {
    this.sidebarVisible = false;
  }

  openSidebar() {
    this.sidebarVisible = true;
  }

  logout() {
    this.closeSidebar();
    this.dashboardService.makeSidebarButtonInvisible();
    this.defaultService.configuration.credentials = { "bearer": "" };
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
