<app-breadcrumb></app-breadcrumb>
<h1>Product Categories</h1>
<p-toast></p-toast>

<!-- Create Category Dialog -->

<p-dialog header="Create Category" [(visible)]="showCreateCategoryDialog" [modal]="true" [style]="{ width: '50vw'}" [draggable]="false" [resizable]="false" class="sticky-dialog">
    <div class="scrollable-dialog-content">
        <div class="p-fluid flex-column">
            <div class="p-field">
                <label for="categoryName">Category Name</label>
                <input id="categoryName" type="text" pInputText [(ngModel)]="newCategory.name" />
            </div>

            <div class="flex-grow"></div>
            <button pButton pRipple type="button" label="Create Category" icon="pi pi-plus" class="p-button" (click)="createCategory()" [disabled]="!newCategory.name"></button>
        </div>
    </div>
</p-dialog>


<button pButton pRipple type="button" label="Create Category" icon="pi pi-plus" class="p-button" (click)="showCreateCategoryDialog = !showCreateCategoryDialog"></button>

@for (category of categories; track $index) {
    <div class="m-5 border">
        <span>        
            <h3 class="inline-flex">{{ category.name }}</h3>
            <button  pButton pRipple type="button" pCancelEditableRow icon="pi pi-trash" (click)="onRowDelete(category)" class="p-button-rounded p-button-text p-button-danger float-right"></button>

            <p-button [routerLink]="['/dashboard/categories', category.id, 'products']" class="p-button-rounded p-button-text float-right" icon="pi pi-arrow-up-right" ></p-button>

        </span>

    </div>
} 
@empty {
    <h3 class="center">No Categories</h3>
}