import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import {environment} from "./environments/environment";
import {enableProdMode} from "@angular/core";
import localeDeAt from '@angular/common/locales/de-AT';
import {registerLocaleData} from "@angular/common";


console.log("isProduction: " + environment.production)

registerLocaleData(localeDeAt);

if(environment.production) {
    enableProdMode();
}
bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
