import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DefaultService } from '../../../../../../../shared-lib/src/lib/api';
import { Observable } from 'rxjs';
import { lastValueFrom } from 'rxjs';


interface PrimeNgBreadcrumbItem {
  label: string;
  routerLink?: string;
}

interface PrimeNgBreadcrumbHome {
  icon: string;
  routerLink: string;
}

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [BreadcrumbModule],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent implements OnInit {

  items: PrimeNgBreadcrumbItem[] = [];
  home: PrimeNgBreadcrumbHome = { icon: 'pi pi-home', routerLink: '/' };

  @Output() outCategoryName = new EventEmitter<string>();
 
  constructor(private router: Router, private defaultService: DefaultService) { }

  async ngOnInit(): Promise<void> {

 
    const currentRoute = this.router.routerState.snapshot;

    // Access route parameters
    const params = currentRoute.root.params;

    // Access the complete route URL
    const url = currentRoute.url;

    // create breadcrumb items from route but wait for all promises to resolve
    this.items = await this.createBreadcrumbItems(url);
  }

  async createBreadcrumbItems(url: string): Promise<PrimeNgBreadcrumbItem[]> {
    const items: PrimeNgBreadcrumbItem[] = [];
    const urlParts = url.split('/');
    let currentUrl = '';
    for (const part of urlParts) {
      currentUrl += '/' + part;
      // remove // 
      currentUrl = currentUrl.replace('//', '/');
      if (part === '') {
        continue
      }
      
      // if part is a uuid, get the name from the backend
      if (part.length === 36){
        // get previous part
        const previousPart = items[items.length - 1].label;

        if(previousPart !== 'categories') {
          continue;
        }
        //TODO fix anytime
        const res: any[] = await lastValueFrom( this.decideWhichIdToFind(previousPart, part) ) as unknown as any[];
        if (res.length > 0) {
          const name = res[0].name;
          items.push({ label: name });
          this.outCategoryName.emit(name);
        }
      } else {
        items.push({ label: part, routerLink: currentUrl });
      }

    }
    console.log(items);
    return items;

  }
  decideWhichIdToFind(previousPart: string, part: string): Observable<any[]> {
    if("categories" === previousPart) {
      return this.defaultService.categoriesControllerFindOne(part) as unknown as Observable<any[]>;
    } 
    return new Observable<any[]>();
  }


}
