import { Routes } from '@angular/router';
import { SignUpComponent } from './components/auth/signup/signup.component';
import { LoginComponent } from './components/auth/login/login.component';
import { OrdersComponent } from './pages/dashboard/orders/orders.component';
import { ProductsComponent } from './pages/dashboard/categories/products/products.component';
import { OverviewComponent } from './pages/dashboard/overview/overview.component';
import { SettingsComponent } from './pages/dashboard/settings/settings.component';
import { CategoriesComponent } from './pages/dashboard/categories/categories.component';
import { AuthGuard } from './components/auth/auth.guard';


export const routes: Routes = [
    { path: 'login',  component: LoginComponent },
    { path: 'signup', component: SignUpComponent },
    { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard] }, // Protected by AuthGuard
    { path: 'dashboard/overview', component: OverviewComponent, canActivate: [AuthGuard] }, // Protected by AuthGuard
    { path: 'dashboard/categories', component: CategoriesComponent, canActivate: [AuthGuard] }, // Protected by AuthGuard
    { path: 'dashboard/categories/:categoryId/products', component: ProductsComponent, canActivate: [AuthGuard] }, // Protected by AuthGuard
    { path: 'dashboard/orders', component: OrdersComponent, canActivate: [AuthGuard] }, // Protected by AuthGuard
    { path: 'dashboard/settings', component: SettingsComponent, canActivate: [AuthGuard] }, // Protected by AuthGuard
    { path: 'dashboard', redirectTo: '/dashboard/overview', pathMatch: 'full' },
    { path: '', redirectTo: '/dashboard/overview', pathMatch: 'full' },
];