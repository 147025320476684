import { Component } from '@angular/core';
import {BreadcrumbComponent} from "../../../components/auth/util/breadcrumb/breadcrumb.component";

@Component({
  selector: 'app-overview',
  standalone: true,
    imports: [
        BreadcrumbComponent
    ],
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss'
})
export class OverviewComponent {

}
