import { Component } from '@angular/core';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext'; // Add this import
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthService, DefaultService, User } from '../../../../../../shared-lib/src/lib/api';
import { HttpClientModule } from '@angular/common/http';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
 
@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [CommonModule, CheckboxModule, ButtonModule, InputTextModule, RouterModule, FormsModule, HttpClientModule, ProgressSpinnerModule, ToastModule],
  providers: [AuthService, DefaultService],
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss'
})
export class SignUpComponent {

  public newUser = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
    isShopOwner: true,
  };

  public newShop = {
    name: ''
  }


  public signUpNotComplete: boolean = true;

  constructor(private router: Router, private authService: AuthService, private defaultService:DefaultService) { }

  ngOnInit(): void {
  }

  signUp() {
    console.log('Registering the user');
    this.signUpNotComplete = false;
    console.log(this.newUser);

    this.authService.authControllerSignup(this.newUser).subscribe((response: any) => {
      console.log(response);
      const userId = response.data.user.id;

      this.defaultService.shopsControllerCreate({country: "test", ownerfirstname: "test", ownerlastname:"test", street:"tet", zipcode:"tasdas", name: this.newShop.name }).subscribe((shop: any) => {
        console.log(shop);
        const shopId = shop.id;

        const createSettings = {
          shopid: shopId,
          data: {
            pickupLocations: ["Hauptabholort"]
          }
        }
    
        this.defaultService.settingsControllerCreate(createSettings).subscribe((settings: any) => {
          console.log("settings created")

          this.defaultService.usersControllerCreate({authuserid: userId , firstname: this.newUser.firstname, lastname: this.newUser.lastname, shopid: this.newUser.isShopOwner? shopId : null}).subscribe((user: any) => {
            console.log(user);
            this.router.navigate(['/login']);
          });
        });
    
   

      });

    });
  }

}
