import { CanActivateFn, Router } from '@angular/router';
import { AuthResponseDto, AuthService } from '../../../../../shared-lib/src/lib/api';
import { inject } from '@angular/core';
import { catchError, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DashboardService } from '../../pages/dashboard/dashboard.service';

export const AuthGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);
  const httpClient: HttpClient = inject(HttpClient);
  const authService: AuthService = inject(AuthService);
  const dashboardService: DashboardService = inject(DashboardService);


  return authService.authControllerVerifyToken(
    {access_token: localStorage.getItem('access_token')} as AuthResponseDto).pipe(
    switchMap(response => {
      console.log(response);
      if (!response) {
        console.log('Token is invalid');
        localStorage.removeItem('access_token');
        dashboardService.makeSidebarButtonInvisible();
        return of(router.createUrlTree(['/login']));
      }  
      console.log('Token is valid');
      dashboardService.makeSidebarButtonVisible();
      return of(true);
    }),
    catchError(error => {
      console.log('Token is invalid');
      console.error('Error handler:', error);
      localStorage.clear();
      dashboardService.makeSidebarButtonInvisible();
      // Instead of navigating inside catchError, return an Observable of UrlTree for redirection
      return of(router.createUrlTree(['/login']));
    })
  );
};
