import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  public sidebarButtonVisible: boolean = false;

  constructor() { }
   
  makeSidebarButtonVisible() {
    console.log('Making sidebar button visible');
    this.sidebarButtonVisible = true;
  }

  makeSidebarButtonInvisible() {
    console.log('Making sidebar button invisible');
    this.sidebarButtonVisible = false;
  }
}
