import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedLibService {

  constructor() { }


  calcGrossPrice(netPrice: number, tax: number): number {
    return netPrice + (netPrice * (tax / 100));
  }
}
