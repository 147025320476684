/*
 * Public API Surface of shared-lib
 */

import { environment } from '../../fastro-efe/src/environments/environment';
import { Configuration, ConfigurationParameters } from './lib/api';

export * from './lib/shared-lib.service';
export * from './lib/shared-lib.component';
 
// needed for openapi generated code to authenticate
export function apiConfigFactory(): Configuration {
    const accessToken = localStorage.getItem('access_token') ?? 'missing_access_token';
    console.log('Access token:', accessToken);
    const params: ConfigurationParameters = {
        basePath: environment.basePath,
        credentials: { "bearer": accessToken }
    };
    return new Configuration(params);
  }