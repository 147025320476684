<app-breadcrumb></app-breadcrumb>

<h1>Orders</h1>
<h3>{{selectedDate | date: 'EEEE d MMMM y': 'UTC+2': 'de-AT' }}</h3>
<div  class="card-container">
    <p-calendar [inline]="true" [(ngModel)]="selectedDate" (ngModelChange)="newDateSelectedEvent($event)">
        <ng-template pTemplate="date" let-date>
            <div [ngClass]="{'blue-dot': setBlueDotIfOrder(date)}">{{date.day}}</div>
        </ng-template>
    </p-calendar>

    <div>

        @if (orders.length == 0) {
            <div class="middle">No Orders</div>
        } @else {
            <p-card header="Total Order" subheader="" [style]="{ width: '360px' }">
                <ng-template pTemplate="header">
                </ng-template>
                <div>
                        @for(entry of dayOrder.entries(); track $index) {
                        <p class="bold">{{entry[0]}}</p>

                            @for(product of entry[1].entries(); track $index) {

                                <p>{{product[1].quantity}}x {{product[0]}} {{product[1].totalPrice| currency: 'EUR' }}</p>
                            }
                        }
                    <p-divider></p-divider>


                </div>
            </p-card>
        }
    </div>

</div>


<p-divider></p-divider>
@if (orders.length != 0) {
    <h2>Single Orders</h2>
}
<div class="card-container ">
    @for (order of orders; track $index) {
        <div>
            <p-card header="" subheader="{{ order.id  }}" [style]="{ width: '360px' }">
                <ng-template pTemplate="header">

                </ng-template>
                <div>
                    <h4>Kunden Informationen:</h4>
                    <p>Vorname: {{ order.customerfirstname }}</p>
                    <p>Nachname: {{ order.customerlastname }}</p>
                    <p>Abholort: {{ order.pickuplocation }}</p>
                    <p>Abholtag: {{ getLocaleDateString(order.pickupdate) }}</p>

                    <h4>Bestell Informationen:</h4>
                        @for (orderitem of order.orderItems; track $index) {
                            <div>
                                <div class="inline">
                                    <span> {{ orderitem.quantity }} x </span>
                                    <span> {{ orderitem.products.name }} </span>
                                    <span> {{ calcGrossPrice(orderitem.products.netprice, orderitem.products.tax, orderitem.quantity) | currency: 'EUR' }}</span>
                                </div>
                            </div>
                        }
                        <div class="inline bold">
                            <span> Summe: </span>
                            <span>{{ calcTotal(order) | currency: 'EUR' }}</span>
                        </div>
                </div>

                <ng-template pTemplate="footer">
                    <div class="flex gap-3 mt-1">
                        <p-button icon="pi pi-times" label="Cancel" severity="danger" class="w-full"
                                  styleClass="w-full"/>
                        <p-button icon="pi pi-check" label="Done" class="w-full" styleClass="w-full"/>
                    </div>
                </ng-template>
            </p-card>
        </div>

    }
</div>

