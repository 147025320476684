import {DeferBlockDepsEmitMode} from '@angular/compiler';
import {Component, LOCALE_ID, OnInit} from '@angular/core';
import {DefaultService, Order} from '../../../../../../shared-lib/src/lib/api';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {BreadcrumbComponent} from '../../../components/auth/util/breadcrumb/breadcrumb.component';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import {FormsModule} from '@angular/forms';
import {ToastModule} from 'primeng/toast';
import {MessageModule} from 'primeng/message';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ButtonModule} from 'primeng/button';
import {SharedLibService} from '../../../../../../shared-lib/src/public-api';
import {CardModule} from 'primeng/card';
import {CalendarModule} from "primeng/calendar";
import {DividerModule} from "primeng/divider";


interface OrderProductWithProductNames {
    orderid: string;
    productid: string;
    quantity: number;
    products: { name: string, tax: number, netprice: number };
}


interface OrderWithProducts extends Order {
    orderItems: OrderProductWithProductNames[];
}

interface DayOrder extends Object {

}

@Component({
    selector: 'app-orders',
    standalone: true,
    imports: [CommonModule, CardModule, HttpClientModule, FormsModule, InputTextModule, TableModule, ToastModule, MessageModule, InputNumberModule, InputTextareaModule, BreadcrumbComponent, ButtonModule, CalendarModule, DividerModule],
    providers: [DefaultService, SharedLibService, //your locale
    ],
    templateUrl: './orders.component.html',
    styleUrl: './orders.component.scss'
})
export class OrdersComponent implements OnInit {


    allOrders: Order[] = [];
    currentShopId: string = localStorage.getItem('shopId') ?? 'no-shop-id-set';
    orders: OrderWithProducts[] = [];
    protected readonly parent = parent;
    selectedDate: Date = new Date(); // default Today
    dayOrder = new Map<string, Map<string, {quantity: number, totalPrice: number}>>();

    constructor(private defaultService: DefaultService, private sharedLibService: SharedLibService) {
    }

    ngOnInit(): void {
        this.defaultService.shopsControllerFindAllOrdersByShopId(this.currentShopId).subscribe((orders: any)=> {
          this.allOrders = orders;
        });
       this.loadOrders(this.selectedDate);
    }

    calcGrossPrice(netPrice: number, tax: number, quantity: number) {
        return this.sharedLibService.calcGrossPrice(netPrice, tax) * quantity;
    }

    cancelEdit() {
        // Handle cancellation logic here
        console.log('Edit canceled');
    }

    saveChanges() {
        // Handle save changes logic here
        console.log('Changes saved');
    }

    protected readonly Date = Date;

    getLocaleDateString(dateStr: string): any {
        return new Date(dateStr).toLocaleDateString();
    }


    calcTotal(order: any): number {
        let total = 0;
        if (order.orderItems && order.orderItems.length > 0) {
            for (let orderItem of order.orderItems) {
                total += this.calcGrossPrice(orderItem.products.netprice, orderItem.products.tax, orderItem.quantity)
            }
            return total;
        }

        return total;
    }

    loadOrders(date: Date) {
        const isoDate = date.toISOString().split('T')[0]
        console.log("orders");
        console.log("loading orders for " + isoDate);
        console.log(this.currentShopId);

        this.defaultService.shopsControllerFindAllOrdersByShopId(this.currentShopId, isoDate).subscribe((response: any) => {
            console.log(response);
            this.orders = response;

            console.log(this.orders);

            this.orders.forEach((order) => {
                this.defaultService.ordersControllerFindAllOrderProductsByOrderId(order.id).subscribe((response: any[]) => {
                    order.orderItems = response as OrderProductWithProductNames[]

                    console.log(order.orderItems);
                    this.calcDayOrder();
                });
            });
        });
    }

    newDateSelectedEvent($event: any) {
        const date = new Date($event)

        // Add one day because of weird date counting
        date.setDate(date.getDate() + 1);
        this.loadOrders(date);
    }


  calcDayOrder() {
    const locationMap = new Map<string, Map<string, {quantity: number, totalPrice: number}>>();

    this.orders?.forEach(order => {
      const location = order.pickuplocation;
      const items = order.orderItems;

      items?.forEach(item => {
        const productName = item.products.name;
        const productQuantity = item.quantity;
        const netPrice = item.products.netprice;
        const taxRate = item.products.tax;
        const priceWithTax = netPrice * (1 + taxRate);

        if (!locationMap.has(location)) {
          locationMap.set(location, new Map());
        }

        const productMap = locationMap.get(location);
        const currentData = productMap?.get(productName) || {quantity: 0, totalPrice: 0};

        productMap?.set(productName, {
          quantity: currentData.quantity + productQuantity,
          totalPrice: currentData.totalPrice + productQuantity * priceWithTax
        });
      });
    });

    console.log(locationMap)

    this.dayOrder = locationMap;
  }

  protected readonly Object = Object;
    setBlueDotIfOrder(date:any) {
     const calDate = new Date(date.year, date.month, date.day + 1).toISOString().split("T")[0]
     const todayDate = new Date().toISOString().split("T")[0]
    return this.findPickupDateInOrder(calDate)
  }

  findPickupDateInOrder(date:string) {
      const found = this.allOrders.find((order => order.pickupdate == date));
      return found !== undefined
    }
}
