import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { SignUpComponent } from './components/auth/signup/signup.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SharedLibComponent } from '../../../shared-lib/src/public-api';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SignUpComponent, LoginComponent, DashboardComponent, SharedLibComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'fastro-efe';
}
