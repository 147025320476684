import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
 
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { Category, CreateCategoryDto, DefaultService } from '../../../../../../shared-lib/src/lib/api';
import {  HttpClientModule } from '@angular/common/http';
import { BreadcrumbComponent } from '../../../components/auth/util/breadcrumb/breadcrumb.component';
  
@Component({
  selector: 'app-categories',
  standalone: true,
  imports: [CommonModule, RouterLink, ButtonModule, DialogModule, InputTextModule, FormsModule, MessageModule, ToastModule, ButtonModule, HttpClientModule, BreadcrumbComponent],
  providers: [MessageService, DefaultService],
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.scss'
})
export class CategoriesComponent implements OnInit{


 

  newCategory: CreateCategoryDto = {
    shopid: '',
    name: ''
  };


  showCreateCategoryDialog: boolean = false;
  categories: Category[] = [];
  selectedCategory: string | null = null;
  currentShopId: string = localStorage.getItem('shopId') ?? 'no-shop-id-set'

  constructor(private route: ActivatedRoute, private messageService: MessageService, private defaultService: DefaultService) {
 
  }

  ngOnInit(): void {
    this.defaultService.shopsControllerFindAllCategoriesByShopId(this.currentShopId).subscribe((response: any) => {
      this.categories = response;
    });

  }

  onCategoryChange() {
    // Handle category change
  }

  navigateToDetails(productId: string) {
    // Navigate to product details
  }

 async createCategory() {
    // Create category
    this.newCategory.shopid = this.currentShopId;
    
    this.defaultService.categoriesControllerCreate(this.newCategory).subscribe((response: Category) => {
      console.log(response);
      this.categories.push({
        id: response.id,
        shopid: response.shopid,
        name: response.name
      } as Category);

      this.showCreateCategoryDialog = false;
      this.messageService.add({severity:'success', summary:'Success', detail: this.newCategory.name + ' Category created'});
    });
  }


  onRowDelete(category: Category) {
    // Delete category
    this.defaultService.categoriesControllerRemove(category.id as string).subscribe(() => {
      this.categories = this.categories.filter((c) => c.id !== category.id);
      this.messageService.add({severity:'success', summary:'Success', detail: category.name + ' Category deleted'});
    });
  }
}
