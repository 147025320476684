import { Component, OnInit } from '@angular/core';
import { CreateSettingDto, DefaultService } from '../../../../../../shared-lib/src/lib/api';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import {BreadcrumbComponent} from "../../../components/auth/util/breadcrumb/breadcrumb.component";

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [FormsModule, ChipsModule, BreadcrumbComponent],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent implements OnInit {

  public settings: any = {}
  public settingsKeys: string[] = [];
  public shopId = "not-set"



  constructor(private defaultService: DefaultService, ) {
  }

  ngOnInit(): void {
    this.shopId = localStorage.getItem("shopId") ?? "not-set";
    this.defaultService.settingsControllerFindByShopId(this.shopId).subscribe((settings: any) => {
      this.settings = settings[0];
      this.settingsKeys = Object.keys(settings[0].data);
    });
    
  }


  public saveLocations() {
    console.log("saving locations")
    this.defaultService.settingsControllerUpdate(this.settings.id, {
      data: this.settings.data,
      shopid: this.shopId
    }).subscribe((updatedData:any) => {
      console.log(updatedData)
    });
  }


}
