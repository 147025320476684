import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { AuthService, DefaultService } from '../../../../../../shared-lib/src/lib/api';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CheckboxModule, ButtonModule, InputTextModule, RouterModule, FormsModule, HttpClientModule, ToastModule],
  providers: [AuthService, MessageService],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  public loginUser = {
    email: '',
    password: '',
  };


  constructor(private authService: AuthService, private messageService: MessageService, private router: Router, private defaultService: DefaultService) { }

  login() {
    console.log('Signing in the user');
    console.log(this.loginUser);

    // handle error and success 
    this.authService.authControllerLogin(this.loginUser).subscribe({
      next: (response: any) => {
        // Handle the successful response
        console.log(response);
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('shopId', response.shopId);
        this.defaultService.configuration.credentials = { "bearer": response.access_token };
        this.showToastMessage('Successfully logged in', 'Success', 'success');
        this.router.navigate(['/dashboard']);
      },
      error: (error) => {
        // This block will only execute if catchError is used
        console.error('Error handler:', error);
        this.showToastMessage('Invalid credentials', 'Error', 'error');
      }
    });
  }


  showToastMessage(message: string, summary: string, severity: 'info' | 'warn' | 'error' | 'success') {
    this.messageService.add({ severity: severity, summary: summary, detail: message });
  }

}