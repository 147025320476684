<app-breadcrumb></app-breadcrumb>

<h1>Overview</h1>


<h2 class="center">In progress 🏗️ ... </h2>


<h2>ideas</h2>
rechts oben zeitraum angeben => default heute

<p>Voraussichtliche Einnahmen: 131€ heute </p>

<p>Graph balken diagram bestellungen pro tag in der Woche</p>
<p>Neue Bestellungen heute/diese Woche/ dieses Monat / dieses Jahr </p>
<p>Auzuliefern heute /diese Woche/ dieses Monat / dieses Jahr</p>