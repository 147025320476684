<app-breadcrumb (outCategoryName)="setTitle($event)"></app-breadcrumb>

<h1>{{title}}</h1>

<!-- Dialog -->

<p-dialog header="Add Product" [(visible)]="showAddProductDialog" [modal]="true" [style]="{ width: '50vw', height: '90vh' }" [draggable]="false" [resizable]="false" class="sticky-dialog">
    <div class="scrollable-dialog-content">
        <!-- Your existing content -->
        <div class="p-fluid">
            <div class="p-field">
                <label for="name">Name</label>
                <input id="name" type="text" pInputText [(ngModel)]="newProductWithAllergens.name" />
            </div>
            <div class="p-field">
                <label for="description">Description</label>
                <textarea id="description" pInputTextarea [(ngModel)]="newProductWithAllergens.description"></textarea>
            </div>
            <div class="p-field">
                <label for="netprice">Net Price</label>
                <p-inputNumber id="netprice" [(ngModel)]="newProductWithAllergens.netprice" mode="currency" currency="EUR" locale="de-DE" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
            </div>
            <div class="p-field">
                <label for="tax">Tax</label>
                <p-inputNumber  id="tax" [(ngModel)]="newProductWithAllergens.tax"  inputId="percent" suffix="%"> </p-inputNumber>
            </div>
            <div class="p-field">
                <label for="status">Status</label>

                <p-dropdown [options]="statuses" appendTo="body" [(ngModel)]="newProductWithAllergens.status" [style]="{'width':'100%'}"></p-dropdown>
          
            </div>

            <div class="p-field">
                <label for="allergens">Allergens</label>
                <p-multiSelect [options]="allergensToSelect" [(ngModel)]="newProductWithAllergens.allergens" placeholder="Select Allergens" optionLabel="name" display="chip" [showClear]="true"></p-multiSelect>
            </div>

            <!-- Add more fields for other product attributes as needed -->

             <button pButton pRipple type="button" label="Add Product" icon="pi pi-plus" class="p-button" (click)="addProduct()" [disabled]="!newProductWithAllergens.name || !newProductWithAllergens.netprice || !newProductWithAllergens.status"></button>
        </div>
    </div>
</p-dialog>
 

 
<div class="card">
    <p-toast></p-toast>
    <button pButton pRipple type="button" label="Add Product" icon="pi pi-plus"  class="p-button" (click)="showAddProductDialog = !showAddProductDialog"></button>
    <p-table [value]="products" dataKey="id" editMode="row" [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
            <tr> 
                <th style="width:20%">Name</th>
                <th style="width:20%">Inventory Status</th>
                <th style="width:20%">Gross Price</th>
                <th style="width:10%">Tax </th>
                <th style="width:10%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="product">
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="product.name" required>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{product.name}} 
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="statuses" appendTo="body" [(ngModel)]="product.status" [style]="{'width':'100%'}"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{product.status}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="product.netprice">
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ product.netprice * (1.0 + (product.tax/100)) | currency: 'EUR'}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="product.tax">
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{  product.tax }}%
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <div class="flex align-items-center justify-content-center gap-2">
                        <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(product)" class="p-button-rounded p-button-text"></button>
                        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(product)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(product, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                        <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-trash" (click)="onRowDelete(product)" class="p-button-rounded p-button-text p-button-danger"></button>

                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>