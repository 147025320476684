import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApiModule } from '../../../shared-lib/src/lib/api';
import { apiConfigFactory } from '../../../shared-lib/src/public-api';
  

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),       
    provideAnimations(),
    provideHttpClient(),
    importProvidersFrom([ApiModule.forRoot(apiConfigFactory)]),
  ]
};